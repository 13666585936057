import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

const WitAtOldPath: React.FC = () => {
  const actualSlug = '/weiterbildungs-interessentest/'

  // since HOWI-2416 - must be kept because of bookmarks or spelling mistakes :)
  useEffect(() => {
    navigate(actualSlug, { replace: true })
  })
  return <a href={actualSlug}>Umleitung zum Weiterbildungs-interessentest</a>
}

export default WitAtOldPath
